import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import { Button, ButtonWrapper } from "../components/Button";
import { Modal } from "../components/Dialog";
import Layout from "../components/Layout";
import { BaseLivlyApiResponse } from "../types/Base";
import {
  Inspection,
  InspectionItemType,
  InspectionSectionTypeEnum,
  PostInspectionChecklist,
} from "../types/Inspection";
import { BASE_API_URL } from "../utils/constants";
import SignatureCanvas from "react-signature-canvas";
import { trackCompleteInspectionChecklist } from "../utils/analytics";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";
import { isNativeAppVersion } from "@/utils/nativeAppHelpers";
import { isAndroid, isIOS } from "react-device-detect";
import toast from "react-hot-toast";

const INSPECTION_KEY = "inspection-checklist";
type InspectionChecklistLocalStorage = {
  leaseId: number;
  inspection: Inspection;
};

async function getInspectionChecklist(propertyId: number) {
  const result = await axios.get<BaseLivlyApiResponse<Inspection>>(
    `${BASE_API_URL}/livly/inspection/template/property/${propertyId}`
  );

  return result.data.Data;
}

const inspectionChecklistQuery = (propertyId: number, leaseId: number) => ({
  queryKey: ["inspection-checklist", propertyId, leaseId],
  queryFn: async () => getInspectionChecklist(propertyId),
});

export const postInspectionPhoto = async (leaseId: number, base64: string) => {
  const result = await axios.post<BaseLivlyApiResponse<{ uri: string }>>(
    `${BASE_API_URL}/livly/inspection/lease/${leaseId}/image`,
    { data: base64 }
  );

  return result.data.Data.uri;
};

const postInspectionChecklist = async (
  leaseId: number,
  data: PostInspectionChecklist
) => {
  const request: PostInspectionChecklist = {
    inspectionSections: data!.inspectionSections.map((section) => ({
      inspectionSectionTypeId: section.inspectionSectionTypeId,
      inspectionItems: section.inspectionItems,
    })),
    signature: data.signature!.replace(/^data:(.*;base64,)?/, ""),
  };

  const result = await axios.post<BaseLivlyApiResponse<null>>(
    `${BASE_API_URL}/livly/inspection/lease/${leaseId}`,
    request,
    { headers: { "Content-Type": "application/json" } }
  );

  return result.data.Data;
};

export type InspectionChecklistOutletContent = {
  inspection: Inspection;
  setInspection: (inspection: Inspection) => void;
  updateInspectionItem: (sectionId: string, item: InspectionItemType) => void;
  onUploadPhotos: (
    sectionId: string,
    item: InspectionItemType,
    decodedPhotos: string[]
  ) => Promise<void>;
  isUploadingImages: boolean;
  setSignature: (signature: string) => void;
  onSubmit: () => void;
  isSaving: boolean;
};

function InspectionChecklistContainer() {
  const { user } = useLivlyUser();
  const { mutateAsync, isLoading: isUploadingImages } = useMutation(
    (base64: string) => postInspectionPhoto(user.propertyUnitLeaseId, base64)
  );
  const postInspectionChecklistMutation = useMutation({
    mutationFn: (data: PostInspectionChecklist) =>
      postInspectionChecklist(user.propertyUnitLeaseId, data),
  });

  const [inspection, setInspection] = useState<Inspection | undefined>(
    undefined
  );
  const [signature, setSignature] = useState("");

  const { isLoading } = useQuery({
    ...inspectionChecklistQuery(user.propertyId, user.propertyUnitLeaseId),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const inspectionChecklistFromLocalStorage =
        localStorage.getItem(INSPECTION_KEY);

      if (inspectionChecklistFromLocalStorage) {
        const parsedInspectionChecklist: InspectionChecklistLocalStorage =
          JSON.parse(inspectionChecklistFromLocalStorage);

        if (parsedInspectionChecklist.leaseId === user.propertyUnitLeaseId) {
          setInspection(parsedInspectionChecklist.inspection);
        } else {
          setInspection(data);
          localStorage.removeItem(INSPECTION_KEY);
        }
      } else {
        setInspection(data);
      }
    },
  });

  const updateInspectionItem = (
    sectionId: string,
    item: InspectionItemType
  ) => {
    if (!inspection) {
      return;
    }

    const newInspection: Inspection = {
      ...inspection,
      inspectionSections: inspection.inspectionSections.map((is) => {
        if (is.inspectionSectionTypeId.toString() !== sectionId) {
          return is;
        }

        return {
          ...is,
          inspectionItemTypes: is.inspectionItemTypes.map((itt) => {
            if (itt.inspectionItemTypeId === item.inspectionItemTypeId) {
              return { ...itt, ...item };
            }
            return itt;
          }),
        };
      }),
    };

    const inspectionForLocalStorage: InspectionChecklistLocalStorage = {
      leaseId: user.propertyUnitLeaseId,
      inspection: newInspection,
    };
    localStorage.setItem(
      INSPECTION_KEY,
      JSON.stringify(inspectionForLocalStorage)
    );

    setInspection(newInspection);
  };

  const onUploadPhotos = async (
    sectionId: string,
    item: InspectionItemType,
    decodedPhotos: string[]
  ) => {
    const images = await Promise.all(
      decodedPhotos.map(async (request) => {
        return await mutateAsync(request);
      })
    );

    updateInspectionItem(sectionId, {
      ...item,
      images: [...(item.images ?? []), ...images],
    });
  };

  const onSubmitInspectionChecklist = () => {
    const request: PostInspectionChecklist = {
      inspectionSections: inspection!.inspectionSections.map((section) => ({
        inspectionSectionTypeId: section.inspectionSectionTypeId,
        inspectionItems: section.inspectionItemTypes,
      })),
      signature: signature!.replace(/^data:(.*;base64,)?/, ""),
    };

    trackCompleteInspectionChecklist();
    postInspectionChecklistMutation.mutate(request, {
      onSuccess: () => {
        //handle return to native view or is there an app bar??
        if (isNativeAppVersion()) {
          finishChecklist();
        }
        localStorage.removeItem(INSPECTION_KEY);
      },
      onError: (data) => {
        const error = data as { data?: { Message?: string } };
        const errorMessage =
          error.data?.Message ??
          "There was an error submitting inspection checklist";
        toast.error(errorMessage);
      },
    });
  };

  if (isLoading || !inspection) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner color="livly" size="xl" />
      </div>
    );
  }

  return (
    <Outlet
      context={{
        inspection,
        setInspection,
        updateInspectionItem,
        onUploadPhotos,
        isUploadingImages,
        setSignature,
        onSubmit: onSubmitInspectionChecklist,
        isSaving: postInspectionChecklistMutation.isLoading,
      }}
    />
  );
}

function InspectionChecklistPage() {
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const data = useOutletContext() as InspectionChecklistOutletContent;

  return (
    <Layout title="Inspection checklist">
      <div>
        <h4 className="font-medium">
          Pre-inspect your apartment before officially settling in.
        </h4>
        <p className="mt-2 text-sm">
          Turn on the toggle for any damaged items to specify the problem and
          provide an image
        </p>
      </div>
      <div className="mt-8">
        <p className="font-medium">My Rooms</p>
        <ul className="pb-24 divide-y divide-gray-200">
          {data.inspection.inspectionSections
            .sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1))
            .map((section) => (
              <li className="py-4" key={section.inspectionSectionTypeId}>
                <Link
                  className="flex items-center gap-4"
                  to={section.inspectionSectionTypeId.toString()}
                >
                  <span className="flex items-center justify-center bg-blue-100 rounded-lg h-14 w-14">
                    <FontAwesomeIcon
                      icon={[
                        "fas",
                        getSectionIconName(section.inspectionSectionTypeId),
                      ]}
                      className="text-xl text-blue-600"
                    />
                  </span>
                  <p className="flex-1 font-medium">{section.name}</p>
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="text-gray-500"
                  />
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <ButtonWrapper>
        <Button
          color="secondary"
          className="w-full md:w-auto"
          onClick={() => setIsSubmitOpen(true)}
        >
          Submit
        </Button>
      </ButtonWrapper>
      <SubmitModal
        termsOfService={data.inspection.termsOfService}
        isOpen={isSubmitOpen}
        setSignature={data.setSignature}
        onClose={() => setIsSubmitOpen(false)}
        isLoading={data.isSaving}
        onConfirm={data.onSubmit}
      />
      <Outlet context={data} />
    </Layout>
  );
}

export { InspectionChecklistContainer, InspectionChecklistPage };

export const getSectionIconName = (
  type: InspectionSectionTypeEnum
): IconName => {
  switch (type) {
    case InspectionSectionTypeEnum.Bathroom:
      return "toilet";
    case InspectionSectionTypeEnum.Bedroom:
      return "bed";
    case InspectionSectionTypeEnum.Hallway:
      return "door-open";
    case InspectionSectionTypeEnum.Kitchen:
      return "oven";
    case InspectionSectionTypeEnum.LivingRoom:
      return "couch";
    case InspectionSectionTypeEnum.Other:
    default:
      return "question-circle";
  }
};

function SubmitModal({
  termsOfService,
  isOpen,
  onClose,
  setSignature,
  onConfirm,
  isLoading,
}: {
  termsOfService: string;
  isOpen: boolean;
  onClose: () => void;
  setSignature: (signature: string) => void;
  onConfirm: () => void;
  isLoading: boolean;
}) {
  const [showSignature, setShowSignate] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const sigCanvas = useRef<SignatureCanvas | null>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShowSignate(true), 100);
    } else {
      setTimeout(() => setShowSignate(false), 200);
    }
  }, [isOpen]);

  const onEnd = () => {
    if (sigCanvas.current) {
      const sig = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      if (sig) {
        setSignature(sig);
      }
    }
  };

  const onClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
    setSignature("");
  };

  return (
    <Modal title="Submit Checklist" open={isOpen} onClose={onClose}>
      <div className="mt-4" id="submit-wrapper">
        <p>{termsOfService}</p>
        <label className="flex items-center gap-4 mt-4">
          <input
            type="checkbox"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          />
          <p>I have read and understand</p>
        </label>
        {showSignature && (
          <div className="h-[150px] w-full max-w-md mx-auto mt-4">
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              backgroundColor="#e2e2e2"
              canvasProps={{
                className: "checklistCanvas",
              }}
              onEnd={onEnd}
            />
          </div>
        )}
        <div className="flex justify-end mt-4">
          <Button size="xs" color="default" onClick={onClear}>
            Clear
          </Button>
        </div>
        <div className="mt-8">
          <Button
            color="secondary"
            className="flex items-center w-full gap-2"
            disabled={isLoading || !agreed}
            onClick={onConfirm}
          >
            {isLoading && <Spinner />}
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function finishChecklist() {
  if (isIOS) {
    (window as any).webkit.messageHandlers.checklistFinish.postMessage("");
  } else if (isAndroid) {
    //eslint-disable-next-line
    eval("Android.checklistFinish()");
  }
}
